import React, { useEffect, useMemo, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import { TixHarpa, TixHarpaConnection, TixHarpaDates } from '@gql-types'
import { useQueryParam, useQueryParams, StringParam } from 'use-query-params'
import { Menu, Footer, useLanguage, BaseLayout, withLanguageProvider } from '@tmp'
import { Card, Datepicker, Dropdown, Text } from '@cmp'
import { AnimatedText, ImageSliderWithScrollLock } from '@connected'
import { Element, scroller } from 'react-scroll'
import moment from 'moment'
import { useWindowSize } from '@hooks'
import { isWithinInterval } from 'date-fns'
import cn from 'classnames'
import { allLocales } from '@src/utils/locale'
import { makePath } from '@src/utils/routes'
import shuffle from 'lodash/shuffle'
import { capitalizeFirstLetter, sortByAlphabet } from '@src/utils/helpers'

interface Event {
  allTixHarpa: TixHarpaConnection
}

type EventCard = {
  name?: TixHarpaDates['name']
  startDate?: TixHarpaDates['startDate']
  hall?: TixHarpaDates['hall']
  featuredImage?: TixHarpa['featuredImage']
  translations?: TixHarpa['translations']
  duplicates?: number
  index?: number
  slug?: TixHarpa['slug']
  id?: TixHarpa['id']
}
type EventCards = {
  [key: string]: EventCard[]
}

type EventsByMonths = {
  events: EventCards
  counter: { [key: string]: { [key: string]: number } }
}

const colors = shuffle([
  'text-white bg-violette-400',
  'text-white bg-red-450',
  'text-dark-400 bg-orange-400',
  'text-dark-400 bg-yellow-400',
])

let currentIndex = 0
const getRandomColor = () => colors[currentIndex++ % colors.length]

const PageTemplate = ({ data: { allTixHarpa } }: PageProps<Event>) => {
  const [currentColor, setCurrentColor] = useState('')
  const [dates, setDates] = useQueryParams({ startDate: StringParam, endDate: StringParam })
  const [selectedStartDate, setSelectedStartDate] = useState('')
  const [selectedEndDate, setSelectedEndDate] = useState('')
  const [datesVisible, setDatesVisible] = useState(false)

  const [selectedCategory, setSelectedCategory] = useQueryParam('category', StringParam)
  const [selectedVenue, setSelectedVenue] = useQueryParam('venue', StringParam)
  const { dateFormat, locale, t } = useLanguage()
  const { width } = useWindowSize()

  const [showAllEvents, setShowAllEvents] = useState(false)

  const showDatePicker = (show: boolean) => {
    setDatesVisible(show)
  }

  useEffect(() => {
    // Gatsby was not rehydrating showAllEvents so we force render by setting it to state
    setShowAllEvents(!selectedCategory || meta.categories.indexOf(selectedCategory.toLowerCase()) === -1)

    setCurrentColor(getRandomColor())
  }, [selectedCategory])

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      setDates({ startDate: selectedStartDate, endDate: selectedEndDate })
    }
  }, [selectedStartDate, selectedEndDate])

  useEffect(() => {
    const { startDate, endDate } = dates
    if (startDate && endDate) {
      setSelectedStartDate(startDate)
      setSelectedEndDate(endDate)
    }
  }, [])

  const eventsInRange = useMemo(() => {
    if (allTixHarpa.nodes.length && selectedStartDate && selectedEndDate) {
      const startTimeOffset = moment.duration('06:00:00')
      const start = moment(selectedStartDate).subtract(startTimeOffset).toDate()
      const end = moment(selectedEndDate).toDate()
      const filteredEvents = allTixHarpa.nodes.reduce((prev: TixHarpa[], current) => {
        const eventsWithDatesInRange = current.dates?.filter(date =>
          isWithinInterval(new Date(date?.startDate), { start, end }),
        )

        if (eventsWithDatesInRange?.length) {
          const validDates = current.dates?.filter(date =>
            isWithinInterval(new Date(date?.startDate), { start, end }),
          )

          return [...prev, { ...current, dates: validDates }]
        }
        return prev
      }, [])
      return filteredEvents
    }
  }, [selectedStartDate, selectedEndDate])

  const eventsDisplay = eventsInRange || allTixHarpa.nodes

  const onDatesChange = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    setSelectedStartDate(startDate)
    setSelectedEndDate(endDate)
  }

  const onClearDates = () => {
    setSelectedStartDate('')
    setSelectedEndDate('')
    setDates({ startDate: undefined, endDate: undefined })
  }
  // get a unique array of available categories
  const meta = useMemo(() => {
    const map = allTixHarpa.nodes.reduce<{ categories: string[]; venues: string[] }>(
      (acc, event) => {
        const lang = locale ?? 'is'
        const categories = event?.categoriesMultiLang?.[lang] ?? []
        categories.forEach(category => {
          if (category && acc.categories.indexOf(category.toLowerCase()) === -1) {
            acc.categories.push(category.toLowerCase())
          }
        })
        event?.dates?.forEach(date => {
          const hall = date?.hall ? date.hall.trim() : t('other')
          if (acc.venues.indexOf(hall) === -1) {
            acc.venues.push(hall)
          }
        })
        return acc
      },
      {
        categories: [],
        venues: [],
      },
    )
    return {
      categories: sortByAlphabet(map.categories),
      venus: map.venues.sort(),
    }
  }, [])
  const availableMonths: { title: string; key: string }[] = []
  // If category query is empty or not an available category then show all events
  const eventsByMonths = eventsDisplay.reduce<EventsByMonths>(
    (acc, { featuredImage, dates, name, categoriesMultiLang, slug, id, translations }) => {
      dates?.forEach((date: any) => {
        const startDate = new Date(date?.startDate)
        const dateKey = `${startDate.getFullYear()}${('0' + (startDate.getMonth() + 1)).slice(-2)}`
        const hall = date?.hall ? date.hall.trim() : t('other')
        const availableCategories = categoriesMultiLang?.[locale]?.join(', ') ?? ''
        const eventSlug = slug

        const containsSelectedCategory =
          selectedCategory && availableCategories?.toLowerCase()?.indexOf(selectedCategory.toLowerCase()) !== -1
        const filterVenues = selectedVenue && meta.venus.some(v => v.toLowerCase() === selectedVenue.toLowerCase())
        // if selected venue is invalid or not set we should always return true
        const containsSelectedVenue =
          !filterVenues || (selectedVenue && hall?.toLowerCase() === selectedVenue.toLowerCase())
        if ((showAllEvents && containsSelectedVenue) || (containsSelectedCategory && containsSelectedVenue)) {
          // set new date
          if (!acc.events[dateKey]) {
            acc.events[dateKey] = []
            acc.counter[dateKey] = {}
            availableMonths.push({
              title: dateFormat(new Date(startDate), 'MMMM y'),
              key: dateKey,
            })
          }
          if (!acc.counter[hall]) {
            acc.counter[hall] = {}
          }
          acc.counter[dateKey][id] = acc.counter[dateKey][id] ? acc.counter[dateKey][id] + 1 : 1
          acc.counter[hall][dateKey] = acc.counter[hall][dateKey] ? acc.counter[hall][dateKey] + 1 : 1
          // Filter out all duplicates
          if (acc.counter[dateKey][id] === 1) {
            let translation = translations?.find((translation: any) => translation?.twoLetterCulture === locale)
              ?.name
            acc.events[dateKey].push({
              ...date,
              categoriesMultiLang,
              name: translation || name || date?.name,
              featuredImage,
              eventSlug,
              slug,
              id,
            })
          }
        }
      })
      return acc
    },
    {
      events: {},
      counter: {},
    },
  )
  return (
    <BaseLayout
      defaultSeoOverwrites={{
        title: t('whats-on'),
        ogTitle: t('whats-on'),
        keywords: t(selectedCategory || ''),
      }}
    >
      <div className={cn('transition-colors duration-1000', currentColor)}>
        <main className='relative pb-40'>
          <Menu localizedUrls={allLocales.map(locale => ({ locale, route: { type: 'dagskra' } }))} />
          <div className='gutter py-23'>
            <AnimatedText
              text={capitalizeFirstLetter(showAllEvents ? t('all-events') : t(selectedCategory || '') ?? '')}
              as='h1'
              variant='h1'
            />
            {selectedVenue && meta.venus.some(v => v.toLowerCase() === selectedVenue.toLowerCase()) && (
              <AnimatedText variant='h3' text={`${t('hall')}: ${selectedVenue}`} />
            )}
            {selectedStartDate && selectedEndDate
              ? `${t('period')}: ${dateFormat(new Date(selectedStartDate), 'do MMMM y')} - ${dateFormat(
                  new Date(selectedEndDate),
                  'do MMMM y',
                )}`
              : null}
          </div>
          <div className='-mb-48 md:-mb-32 xl:-mb-20'>
            <div className='flex flex-row flex-wrap w-full pt-12 ml-0 md:pt-0 md:flex-nowrap md: xl:ml-auto lg:w-min gutter'>
              <Dropdown
                title={t('kind')}
                className='z-30 flex-grow flex-shrink min-w-0 mr-4 lg:flex-shrink-0 md:flex-grow-0'
                items={[
                  {
                    title: t('all-events'),
                    onClick: () => {
                      setSelectedCategory('')
                    },
                  },
                  ...meta.categories.map(category => ({
                    title: t(category.charAt(0).toUpperCase() + category.slice(1)),
                    onClick: () => {
                      setSelectedCategory(category.toLowerCase())
                    },
                  })),
                ]}
              />
              <Dropdown
                title={t('hall')}
                className='z-30 flex-grow flex-shrink min-w-0 mr-0 md:z-20 md:mr-4 lg:flex-shrink-0 md:flex-grow-0'
                items={[
                  {
                    title: t('all'),
                    onClick: () => {
                      setSelectedVenue('')
                    },
                  },
                  ...meta.venus.map(title => ({
                    title,
                    onClick: () => {
                      setSelectedVenue(title)
                    },
                  })),
                ]}
              />

              <Datepicker
                className='z-20 flex-grow flex-shrink min-w-0 mt-3 mr-4 md:mt-0 lg:flex-shrink-0 md:flex-grow-0'
                clearText={t('clear')}
                endDate={selectedEndDate ? moment(selectedEndDate) : null}
                locale={locale}
                onClearDates={onClearDates}
                onDatesChange={onDatesChange}
                numberOfMonths={width && width < 1180 ? 1 : 2}
                placeholder='Dagatal'
                visible={datesVisible}
                setVisible={showDatePicker}
                startDate={selectedStartDate ? moment(selectedStartDate) : null}
                title={t('period')}
              />
              <Dropdown
                title={t('month')}
                className='z-20 flex-grow flex-shrink min-w-0 mt-3 md:mt-0 lg:flex-shrink-0 md:flex-grow-0 '
                items={availableMonths
                  .sort((a, b) => parseInt(a.key) - parseInt(b.key))
                  .map(({ title, key }) => ({
                    title,
                    onClick: () => {
                      scroller.scrollTo(key, { smooth: true })
                    },
                  }))}
              />
            </div>
          </div>
          {Object.keys(eventsByMonths.events).length === 0 && (
            <>
              <div className='top-0 z-10 md:sticky pb-7 '>
                <Hr />
              </div>
              <div className='py-32 lg:px-10 gutter'>
                <Text variant='h2'>{t('no-events')}</Text>
              </div>
            </>
          )}
          {Object.keys(eventsByMonths.events)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .map((monthKey, idx) => {
              const month = eventsByMonths.events[monthKey].sort(
                (a: EventCard, b: EventCard) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
              )
              const counters = eventsByMonths.counter[monthKey]
              const currentMonth = dateFormat(new Date(month[0].startDate), 'MMMM y')
              return (
                <Element name={monthKey} key={monthKey} className='relative'>
                  <div className='top-0 z-10 md:sticky pb-7'>
                    <Hr />
                    <Text variant='h3' as='h2' className='capitalize gutter pt-7'>
                      {currentMonth}
                    </Text>
                  </div>
                  <div className={cn('pt-52 md:pt-36', { 'pt-0': idx !== 0 }, 'lg:pt-20 lg:px-10')}>
                    <div className='lg:hidden'>
                      <ImageSliderWithScrollLock
                        className='pb-20'
                        cards={month.map((date: any) => {
                          return {
                            title: date.name || '',
                            image: date.featuredImage?.childImageSharp?.resize?.src || '',
                            subtitle: dateFormat(new Date(date.startDate), "dd'.' MMMM '—' p"),
                            href: makePath(locale, { type: 'event', slug: date.eventSlug ?? '' }),
                          }
                        })}
                      />
                    </div>
                    <div className='flex-wrap hidden -mx-2 lg:flex'>
                      {month.map((event: any, idx: number) => {
                        return (
                          <div key={event.id} className='w-full px-2 mb-20 sm:w-1/2 md:w-1/3 lg:w-1/4 2xl:w-1/5'>
                            {event.name && (
                              <Card
                                title={event.name || ''}
                                image={event.featuredImage?.childImageSharp?.resize?.src || ''}
                                subtitle={
                                  event.id && counters[event.id] > 1
                                    ? `${counters[event.id]} ${t('events')}`
                                    : dateFormat(new Date(event.startDate), "do MMMM '—' p")
                                }
                                href={makePath(locale, { type: 'event', slug: event.eventSlug ?? '' })}
                                variant={idx % 2 ? 0 : 1}
                              />
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Element>
              )
            })}
        </main>
        <Footer />
      </div>
    </BaseLayout>
  )
}

const Hr = () => <div className='h-px bg-current opacity-70' />

export default withLanguageProvider(PageTemplate)

export const query = graphql`
  query tixAllQuery {
    allTixHarpa {
      nodes {
        slug
        id
        name
        categoriesMultiLang {
          is
          en
        }
        dates {
          name
          startDate
          categories
          hall
        }
        translations {
          twoLetterCulture
          name
        }
        featuredImage {
          childImageSharp {
            resize(width: 760, quality: 85) {
              src
            }
          }
        }
      }
    }
  }
`
