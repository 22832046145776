import React, { useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { useMenuState } from 'reakit/Menu'
import { motion } from 'framer-motion'
import moment from 'moment'
import 'moment/locale/is'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import Icon from '../Icon/Icon'
import Text from '../Text'
import cn from 'classnames'
import { useIsSwipe, useOnClickOutside } from '@src/hooks'

require('./Datepicker.css')

type DateInput = 'startDate' | 'endDate' | null

export interface DatepickerProps {
  className?: string
  clearText?: string
  endDate: any
  locale?: string
  numberOfMonths: number
  onClearDates: any
  onDatesChange: any
  placeholder: string
  visible: boolean
  setVisible: (show: boolean) => void
  startDate: any
  title: string
  onClickAdd?: () => void
  isDayBlock?: (day: moment.Moment) => boolean
  isNearLeftEdge?: boolean
}

export const Datepicker = ({
  className = '',
  clearText = 'Clear',
  endDate,
  locale = 'is',
  numberOfMonths,
  onClearDates,
  onDatesChange,
  placeholder,
  visible,
  setVisible,
  startDate,
  title,
  onClickAdd,
  isDayBlock,
  isNearLeftEdge,
}: DatepickerProps) => {
  const [hover, setHover] = useState(false)
  const ref = useRef<HTMLButtonElement>(null)

  const menu = useMenuState({ animated: true })
  const [focusedInput, setFocusedInput] = useState<DateInput>(null)
  const { isSwipe } = useIsSwipe()

  useOnClickOutside(ref, () => {
    if (isSwipe) {
      return
    }
    if (visible) {
      setVisible(false)
    }
  })

  useEffect(() => {
    moment.locale(locale)
  }, [])

  const onFocusChange = (focusedInput: DateInput) => {
    setFocusedInput(focusedInput)
  }

  const arrowVariants = {
    visible: { rotate: -45 },
    hidden: { rotate: 45 },
    hover: { translateX: 1, translateY: 3 },
  }
  return (
    <button
      ref={ref}
      className={cn(
        className,
        `flex relative text-left pl-6 pr-4 h-14 w-5/12 md:w-52 uppercase border-t border-l border-r items-center leading-none transition-colors focus:outline-none border-current text-current font-bold`,
        {
          'border-b focus:bg-yellow-400 hover:bg-yellow-400 focus:bg-opacity-20 hover:bg-opacity-20': !menu.visible,
          'bg-white bg-opacity-20 z-30': visible,
          'datepicker-left': isNearLeftEdge,
        },
      )}
      onClick={() => {
        setVisible(true)
        setFocusedInput('startDate')
        onClickAdd ? onClickAdd() : null
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <span className='flex items-center justify-between w-full h-full'>
        <Text className='uppercase' variant='sm' weight='bold'>
          {title}
        </Text>
        <motion.span
          animate={[visible ? 'visible' : 'hidden', hover ? 'hover' : '']}
          variants={arrowVariants}
          className='inline-block ml-auto'
        >
          <Icon name='Arrow' className='w-4 h-auto' />
        </motion.span>
      </span>

      <DateRangePicker
        customArrowIcon={<span className='hidden w-0 h-0'></span>}
        daySize={40}
        transitionDuration={0}
        endDate={endDate}
        endDateId='end_date_id'
        endDatePlaceholderText=''
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
        inputIconPosition='after'
        navPrev={<Icon className='absolute left-7 top-7' name='Chevron' />}
        navNext={<Icon className='absolute transform rotate-180 right-7 top-7' name='Chevron' />}
        noBorder
        onClose={() => setVisible(false)}
        numberOfMonths={numberOfMonths}
        minDate={moment(new Date())}
        onDatesChange={({ startDate, endDate }) => onDatesChange({ startDate, endDate })}
        onFocusChange={focusedInput => onFocusChange(focusedInput)}
        renderWeekHeaderElement={day => (
          <Text className='capitalize' variant='xs'>
            {day}
          </Text>
        )}
        renderCalendarInfo={() =>
          startDate && endDate ? (
            <button onClick={onClearDates} className='px-6 pb-3 focus:outline-none'>
              <Text variant='sm' className='text-dark-400' weight='bold'>
                {clearText}
              </Text>
            </button>
          ) : (
            <div className='h-4'></div>
          )
        }
        renderMonthElement={({ month }) => (
          <Text className='capitalize' weight='light'>
            {moment(month).format('MMMM YYYY')}
          </Text>
        )}
        startDate={startDate}
        startDateId='startDateId'
        startDatePlaceholderText={placeholder}
        weekDayFormat='ddd'
        isDayBlocked={isDayBlock}
      />
    </button>
  )
}

export default Datepicker
